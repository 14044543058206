import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import HeaderBar from '../../../Components/HeaderBar'
import axios from 'axios'
import { useNavigate, useLocation } from 'react-router-dom'
import BarometerEvaluationTable from '../../../Components/BarometerEvaluationTable'
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import url from '../../../Constants/URL.json';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import './index.css'
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

const BarometerEvaluation = () => {
  const navigate = useNavigate()


  const BASE_API_URL = url.BASE_API_URL

  const token = localStorage.getItem('token')
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { state } = useLocation()
  const currentDate = new Date().toISOString()
  const [results, setResults] = useState([])
  const [criterias, setCriterias] = useState([])
  const [subCriterias, setSubCriterias] = useState({})
  const [evaluations, setEvaluations] = useState([])
  const [resultColumns, setResultColumns] = useState([])
  const [subCriteriaResults, setSubcriteriaResults] = useState({})
  const [finalPercent, setFinalPercent] = useState({})
  const [selectedDate, setSelectedDate] = useState();
  const [isDataChange, setIsDataChange] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openWarnDialog, setOpenWarnDialog] = useState(false);
  const [openDeleteWarning, setOpenDeleteWarning] = useState(false);
  const [isClickDeleteEvaluationColumn, setIsClickEvaluationColumn] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [dataToTable, setDataToTable] = useState([]);
  const [isDateChange, setIsDateChange] = useState(false);

  useEffect(() => {
    getCriteriasByCompany()
    getSubCriteriaResultsByCompany()
  }, [])

  const _findFinalPercentageForEvaluationTable = async (_finalPercent, _resultColumn) => {
    // console.log("_finalPercent: ", _finalPercent)
    // console.log("_resultColumn: ", _resultColumn)
    // SECTION 1
    const finalScoreResults = [];
    for (const [id, percentageResult] of Object.entries(_finalPercent)) {
      finalScoreResults.push({ Id: id, percentageResult });
    }
    // console.log("finalScore Result: ", finalScoreResults)

    // SECOND TWO
    const columns = await _resultColumn.map(obj => {
      const { subCriteriaResults, ...rest } = obj;
      return rest;
    });

    // console.log("columns: ", columns);
    // SECTION 3: match data and add to new array
    // Initialize a new array to store the matched data
    let matchedData = [];

    // Loop through each object in arr1
    finalScoreResults.forEach(obj1 => {
      // Find the matching object in arr2 using the "id" property
      let obj2 = columns.find(obj2 => obj2.id === obj1.Id);
      if (obj2) {
        // If a match is found, create a new object with the desired properties
        let match = {
          percentageResult: obj1.percentageResult,
          date: obj2.date
        };
        // Add the new object to the matchedData array
        matchedData.push(match);
      }
    });

    // Print the resulting array to the console
    // console.log(matchedData);
    setDataToTable(matchedData)
  }

  // REMARK: DIALOG START 
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpenWarnDialog(false)
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      // Update the state to close the dialog
      setOpen(false);
    }
    // setOpen(false);
  };
  // REMARK: DIALOG END 

  // DIALOG 2
  const handleOpenWarning = () => {
    setOpenWarnDialog(true);
  };

  const handleCloseWarning = () => {
    setOpenWarnDialog(false);
  };

  const forceExitWithoutSave = () => {
    navigate("/")
  }
  // DIALOG 3
  const handleOpenDeleteWarning = () => {
    setOpenDeleteWarning(true)
  }

  const handleCloseDeleteWarning = () => {
    setOpenDeleteWarning(false)
  }





  const getSubCriteriaResultsByCompany = () => {
    return axios.get(
      `${BASE_API_URL}/barometers/subcriteria_result/company/${state.id}`,
      headers
    )
      .then((response) => {
        const fetchSubCriteriaResult = response.data

        const tmp = {}
        fetchSubCriteriaResult.forEach((r) => {
          const { isChecked, subCriteriaId, resultId } = r
          if (!tmp[resultId + subCriteriaId]) {
            tmp[resultId + subCriteriaId] = {
              isChecked,
              subCriteriaId,
              resultId,
            }
          }
        })
        setSubcriteriaResults(tmp)


        return tmp
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getCriterias = async () => {
    setLoading(true)

    const crits = await axios
      .get(`${BASE_API_URL}/barometers/criteria/company/${state.id}`, headers)
      .then((response) => {
        setCriterias(response.data)
        setLoading(false)
        return response.data
      })
      .catch((error) => {
        console.log(error)
      })
    return crits
  }

  const getCriteriasByCompany = async () => {
    const crits = await getCriterias();

    setOpen(true)

    const res = await axios
      .get(`${BASE_API_URL}/barometers/result/company/${state.id}`, headers)
      .then((response) => {
        setResultColumns(response.data)
        setOpen(false)
        return response.data
      })
      .catch((error) => {
        console.log(error)
      })

    const subRes = await getSubCriteriaResultsByCompany()
    const tmp = {}
    res.forEach((r) => {
      const sum = calculateLoaded(crits, r.id, subRes)
      tmp[r.id] = sum
    })
    setFinalPercent(tmp)
    console.log("res: ", res)

    _findFinalPercentageForEvaluationTable(tmp, res)
  }

  const createNewResult = () => {
    const data = {
      companyId: state.id,
      date: currentDate,
      num: resultColumns.length + 1
    }

    axios
      .post(`${BASE_API_URL}/barometers/result`, data, headers)
      .then((response) => {
        getCriteriasByCompany()
        setResultColumns([...resultColumns, response.data])
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleCreateNewEvaluation = () => {
    // Disable the button
    setIsButtonDisabled(true);

    // Set a timeout to enable the button again after 0.5 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
    createNewResult()
  }

  const calculateLoaded = (criterias, resultId, subRes) => {
    let sum = 0
    let ncrit = criterias.filter(x => x.weight > 0).length
    criterias.forEach((criteria) => {
      let cSum = 0
      let count = 0
      criteria.subCriterias.forEach((sub) => {
        if (criteria.weight > 0) {
          if (criteria.num === 1) {
            if (subRes[resultId + sub.id]) {
              if (subRes[resultId + sub.id].isChecked) {
                cSum += sub.percentageValue
              }
            }
          } else {
            if (subRes[resultId + sub.id]) {
              if (subRes[resultId + sub.id].isChecked) {
                count += 1
                cSum += sub.percentageValue;
              }
            }
          }
        }
      })
      count = count === 0 ? 1 : count
      cSum = criteria.num === 1 ? cSum : Math.round(cSum / count)
      sum += cSum;
    })
    return ncrit === 0 ? 0 : Math.round(sum / ncrit)
  }

  const calculateResult = (criterias, resultId) => {
    let sum = 0
    let ncrit = criterias.filter(x => x.weight > 0).length
    criterias.forEach((criteria) => {
      let cSum = 0
      let count = 0
      criteria.subCriterias.forEach((sub) => {
        if (criteria.weight > 0) {
          if (criteria.num === 1) {
            if (subCriteriaResults[resultId + sub.id]) {
              if (subCriteriaResults[resultId + sub.id].isChecked) {
                cSum += sub.percentageValue
              }
            }
          } else {
            if (subCriteriaResults[resultId + sub.id]) {
              if (subCriteriaResults[resultId + sub.id].isChecked) {
                count += 1
                cSum += sub.percentageValue;
              }
            }
          }
        }
      })
      count = count === 0 ? 1 : count
      cSum = criteria.num === 1 ? cSum : Math.round(cSum / count)
      sum += cSum
    })

    return ncrit === 0 ? 0 : Math.round(sum / ncrit)
  }

  const handleSaveDataChange = () => {
    axios
      .post(
        `${BASE_API_URL}/barometers/subcriteria_result/bulk`,
        Object.values(subCriteriaResults),
        headers
      )
      .then((response) => {
        handleClose()
        window.location.reload()
      })
      .catch((error) => {
        alert('failed')
      })
  }

  const handleSaveDataChangeWhenTryToQuit = () => {
    handleClickOpen()
    axios
      .post(
        `${BASE_API_URL}/barometers/subcriteria_result/bulk`,
        Object.values(subCriteriaResults),
        headers
      )
      .then((response) => {
        setIsDataChange(false)
        if (!isClickDeleteEvaluationColumn) {
          navigate('/')
        }
        setIsClickEvaluationColumn(false)
        handleCloseDeleteWarning()
        handleClose()
      })
      .catch((error) => {
        alert('failed')
      })
  }

  const handleSelectChange = (event, criteriaTitle, criteriaId) => {
    handleClickOpen()

    const data = {
      title: criteriaTitle,
      weight: parseInt(event.target.value),
      companyId: state.id
    };

    const newValue = event.target.value;

    const url = `${BASE_API_URL}/barometers/criteria/${criteriaId}`;

    axios.patch(url, data, headers)
      .then(async (response) => {
        const crits = await getCriterias()
        setCriterias(crits)
        let tmp = finalPercent
        resultColumns.forEach(re => {
          const sum = calculateResult(crits, re.id)
          tmp[re.id] = sum
        })
        setFinalPercent(tmp)
        // window.location.reload()
        handleClose()
      })
      .catch((error) => {
        handleClose()
        alert('failed', error);
      });
  }

  function handleDateChange(event, resultColumnId) {
    handleClickOpen()
    setSelectedDate(event.target.value);
    const data = {
      date: new Date(event.target.value).toISOString(),
    };

    const url = `${BASE_API_URL}/barometers/result/${resultColumnId}`;

    axios.patch(url, data, headers)
      .then(async (response) => {
        // alert("DATE CHANGE SUCCESSFULLY")
        setIsDateChange(true)
        handleClose()
      })
      .catch((error) => {
        console.log("FAILED: ", error)
        alert('failed', error);
        handleClose()
      });
  }

  const handleDeleteEvaluationColumn = (columnId) => {
    handleClickOpen()
    const url = `${BASE_API_URL}/barometers/result/${columnId}`;

    axios.delete(url, headers)
      .then((response) => {
        getCriteriasByCompany()
        handleClose()
      })
      .catch((error) => {
        alert('failed', error);
        handleClose()
      });
  }


  // console.log("resultColumns: ==========================", resultColumns)

  // console.log("Final percent: ", finalPercent)

  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: "1px solid #ECECEC",
        }}
      >
        <IconButton
          disableRipple
          style={{ marginLeft: 10 }}
          onClick={() => {
            // handleSaveDataChange()
            // handleClickOpen()
            isDataChange ? handleOpenWarning() : navigate("/")

          }}
        >
          <ArrowBackRoundedIcon style={{ color: "black" }} />
        </IconButton>
        <HeaderBar
          title={state.companyName}
          // isAllowBack={true}
          destinationPath="/"
        />
      </div>

      <div
        style={{
          fontSize: 13,
          height: '89vh',
          overflowY: 'scroll',
          // paddingLeft: 20,
          // paddingRight: 90,
          // border:"1px solid red"

        }}
      >
        <div

        >
          <div style={{
            width: "83.48vw",
            overflowX: 'auto',
            // border:"2px solid black", 
            paddingLeft: 20,
            marginRight: 20,

          }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                margin: "10px 0"
              }}
            >
              {/* <div style={{ fontSize: 20, fontWeight: 'bold', marginTop: 15, marginBottom: 15 }}>
              Evaluation Table
              </div> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {/* <div>
                  <Button
                    style={{
                      textTransform: 'none',
                      // backgroundColor:"#0f3c50", 
                      backgroundColor: isButtonDisabled || isDataChange ? "#E2E2E2" : "#0f3c50",
                      height: 50,
                      // borderRadius: 200, 
                      // marginLeft: 10, 
                      marginRight: 10
                    }}
                    variant="contained"
                    startIcon={<CloudSyncIcon style={{ paddingLeft: 4, fontSize: 30, paddingRight: 6 }} />}
                    onClick={() => {
                      window.location.reload()
                    }}
                    disabled={isButtonDisabled || isDataChange}
                  >
                    Refresh
                  </Button>
                </div> */}
                {/* <div>Data is not correct?</div> */}
              </div>
            </div>
            <div style={{ position: 'sticky' }}>
              <BarometerEvaluationTable dataToTable={dataToTable} companyName={state.companyName} isDateChange={isDateChange} />
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                margin: '10px 0',
                marginTop: 35,
                textAlign: "center",
                // border:"1px solid black",
                alignItems: "center",
              }}
            >
              <div style={{ fontSize: 20, fontWeight: 'bold' }}>
                {/* Evaluation Criterias <span style={{ fontWeight: "normal" }}>({resultColumns.length} evaluations)</span> */}
              </div>
              <div style={{ marginLeft: 0 }}>
                <Button
                  style={{
                    textTransform: 'none',
                    // backgroundColor:"#0f3c50", 
                    backgroundColor: isButtonDisabled || isDataChange ? "#E2E2E2" : "#0f3c50",
                    height: 50,
                    // borderRadius: 100
                  }}
                  variant="contained"
                  startIcon={<PostAddRoundedIcon style={{ paddingLeft: 6 }} />}
                  onClick={handleCreateNewEvaluation}
                  disabled={isButtonDisabled || isDataChange}
                >
                  Create New Evaluation
                </Button>
              </div>
              {
                isDataChange ? <div style={{ marginLeft: 20 }}>
                  <Button
                    style={{
                      textTransform: 'none',
                      backgroundColor: "#0f3c50",
                      height: 50,
                      // borderRadius: 100
                    }}
                    variant="contained"
                    startIcon={<SaveIcon style={{ paddingLeft: 6 }} />}
                    onClick={() => {
                      handleSaveDataChange()
                      handleClickOpen()
                      setIsDataChange(false)
                    }}
                  >
                    Save Changes
                  </Button>
                </div> : null
              }
            </div>

            {/* REMARK: CRITERIA TABLE */}
            <div className="contain">
              <table className="table_evaluation">
                <thead>
                  <tr>
                    <th
                      rowSpan={4}
                      style={{
                        width: 100,
                        fontSize: 18,
                        // backgroundColor:"#0f3c50",
                        color: "#385623"
                      }}
                    >
                      Weight
                    </th>
                    <th style={{ color: "white" }} rowSpan={4} colSpan={3}><div style={{ width: "55vw", fontSize: 22, paddingLeft: 20 }}>Criterias</div></th>
                  </tr>

                  {[0, 1, 2].map((rowIndex) => (
                    <tr key={rowIndex}>
                      {resultColumns.map((column, columnIndex) => {
                        return (
                          <td
                            key={`result ${1 + columnIndex}`}
                            style={{
                              width: 90,
                              justifyContent: "center",
                              // backgroundColor:"#0f3c50"
                            }}
                          >
                            {
                              isDataChange ?
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    // border:"1px solid black"
                                  }}
                                >
                                  {rowIndex === 0 &&
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        setIsClickEvaluationColumn(true)
                                        isDataChange ? handleOpenDeleteWarning() :
                                          handleDeleteEvaluationColumn(column.id)
                                      }}
                                    >
                                      <DeleteForeverIcon
                                        style={{
                                          color: "#7E7E7E",
                                          fontSize: 25
                                        }} />
                                    </IconButton>}
                                </div>
                                :
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    // border:"1px solid black"
                                  }}
                                >
                                  {rowIndex === 0 &&
                                    <IconButton
                                      aria-label="delete"
                                      onClick={() => {
                                        setIsClickEvaluationColumn(true)
                                        isDataChange ? handleOpenDeleteWarning() :
                                          handleDeleteEvaluationColumn(column.id)
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{
                                          color: "#385623",
                                          fontSize: 25
                                        }} />
                                    </IconButton>}
                                </div>
                            }

                            {rowIndex === 1 && (
                              <div
                                style={{
                                  fontSize: 15,
                                  fontWeight: "bold",
                                  justifyContent: "center",
                                  textAlign: "center",
                                  display: "flex",
                                  flex: 1,
                                  flexDirection: "column",
                                  color:
                                    parseInt(finalPercent[column.id]) >= 0 &&
                                      parseInt(finalPercent[column.id]) <= 9
                                      ? "#ff0000"
                                      : parseInt(finalPercent[column.id]) >= 10 &&
                                        parseInt(finalPercent[column.id]) <= 19
                                        ? "#ed7d31"
                                        : parseInt(finalPercent[column.id]) >= 20 &&
                                          parseInt(finalPercent[column.id]) <= 39
                                          ? "#ffc000"
                                          : parseInt(finalPercent[column.id]) >= 40 &&
                                            parseInt(finalPercent[column.id]) <= 59
                                            ? "#ffd965"
                                            : parseInt(finalPercent[column.id]) >= 60 &&
                                              parseInt(finalPercent[column.id]) <= 79
                                              ? "#c5e0b3"
                                              : parseInt(finalPercent[column.id]) >= 80 &&
                                                parseInt(finalPercent[column.id]) <= 89
                                                ? "#548135"
                                                : parseInt(finalPercent[column.id]) >= 90
                                                  ? "#00b050"
                                                  : ""
                                }}
                              >
                                <div>
                                  {
                                    parseInt(finalPercent[column.id]) >= 0 &&
                                      parseInt(finalPercent[column.id]) <= 9
                                      ? <div style={{}}>Level 1</div>
                                      : parseInt(finalPercent[column.id]) >= 10 &&
                                        parseInt(finalPercent[column.id]) <= 19
                                        ? <div style={{}}>Level 2</div>
                                        : parseInt(finalPercent[column.id]) >= 20 &&
                                          parseInt(finalPercent[column.id]) <= 39
                                          ? <div style={{ color: "black" }}>Level 3</div>
                                          : parseInt(finalPercent[column.id]) >= 40 &&
                                            parseInt(finalPercent[column.id]) <= 59
                                            ? <div style={{}}>Level 4</div>
                                            : parseInt(finalPercent[column.id]) >= 60 &&
                                              parseInt(finalPercent[column.id]) <= 79
                                              ? <div style={{}}>Level 5</div>
                                              : parseInt(finalPercent[column.id]) >= 80 &&
                                                parseInt(finalPercent[column.id]) <= 89
                                                ? <div style={{}}>Level 6</div>
                                                : parseInt(finalPercent[column.id]) >= 90
                                                  ? <div style={{}}>Level 7</div>
                                                  : ""
                                  }
                                </div>
                                {/* <br /> */}
                                <div style={{}}>{(finalPercent[column.id] || 0) + "%"}</div>
                              </div>
                            )}

                            {rowIndex === 2 && <div>
                              <input
                                type="date"
                                style={{
                                  // fontSize: 19,
                                  // backgroundColor: "#F8FDFF",
                                  // fontWeight:"bold"
                                }}
                                id="date"
                                name="date"
                                defaultValue={column.date.substring(0, 10)}
                                onChange={(event) => {
                                  handleDateChange(event, column.id)
                                  getCriteriasByCompany()
                                }}
                              />
                              <div
                                style={{width: 100, textAlign:"center", display:"flex", flexDirection:"row", justifyContent:"center"}}
                              >
                                <div>{new Date(column.date).toLocaleString("en-US", {
                                day: "numeric",
                              })}</div>
                                <div>-</div>
                                <div>{new Date(column.date).toLocaleString("en-US", {
                                month: "short",
                              })}</div>
                              </div>
                            </div>
                            }

                          </td>
                        )
                      })}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {criterias.map((criteria, index) => (
                    <>
                      <tr>
                        <td
                          rowSpan={
                            criteria.subCriterias.length > 0
                              ? criteria.subCriterias.length + 1
                              : 1
                          }

                        >
                          <select
                            style={{
                              width: 50,
                              border: 'none',
                              height: 70,
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontSize: 20,
                              textAlign: "center",
                              fontWeight:"bold",
                              fontFamily:"Poppins",
                              color: "#385623",
                              // backgroundColor: "#F8FDFF",
                            }}
                            defaultValue={criteria.weight}
                            onChange={(event) => {
                              handleSelectChange(event, criteria.title, criteria.id)
                            }}
                          >
                            <option value={1}>1</option>
                            <option value={0}>0</option>
                          </select>
                        </td>

                        <td style={{
                          width: 1000,
                          paddingLeft: 10,
                          fontWeight: "bold",
                          border: "3px solid #385623",
                          borderRight: "0.1px solid white",
                          // backgroundColor:"#065B81", 
                          color: "#385623",
                          fontSize: 21,
                          // display:"block",
                          // overflow:"hidden", 
                          whiteSpace:"nowrap",
                          // textOverflow:"ellipsis"
                          // overflow: "hi";
                          // white-space: nowrap;
                          // text-overflow: ellipsis;

                        }}>{criteria.title}</td>
                        <td style={{ 
                          border: "3px solid #385623",
                          borderLeft: "0.1px solid white" ,
                          borderRight: "0.1px solid white" ,
                          }}></td>
                        <td
                          style={{
                            // width: '100px',
                            // backgroundColor:"#065B81",
                            // border:"1.7px solid red",
                            border: "3px solid #385623",
                            borderLeft: "0.1px solid white",
                            borderRight: "1px solid black",
                          }}
                          colSpan={1}
                        ></td>
                        {resultColumns.map((result, index) => {
                          let sum = 0
                          let count = 0
                          criteria.subCriterias.map((sub) => {
                            let tmp = subCriteriaResults[result.id + sub.id]
                            if (!tmp) {
                              tmp = {
                                isChecked: false,
                                resultId: result.id,
                                subCriteriaId: sub.id,
                              }
                            }
                            if (tmp.isChecked) {
                              count += 1
                              sum += sub.percentageValue
                            }
                          })
                          count = count === 0 ? 1 : count
                          return <td key={result.id + 'percent'} style={{
                            textAlign: "center",
                            fontSize: 16,
                            // fontWeight: "bold", 
                            // backgroundColor:"#065B81",
                            border:"3px solid #385623",
                            color: "#385623",
                            borderLeft:"1px solid black",
                            borderRight:index == resultColumns.length-1?"3px solid #385623":"1px solid black",
                          }}>{criteria.num === 1 ? sum : Math.round(sum / count)}%</td>
                        })}
                      </tr>
                      {criteria.subCriterias.map((sub, index) => (
                        <tr key={sub.id} >
                          <td style={{
                            paddingLeft: 10,
                            textAlign: "center",
                            // width: 150,
                            color: "#385623",
                            fontStyle: "italic",
                            border:index != 0 || index != criteria.subCriterias.length -1 ? "1px dotted #385623": null,
                            borderLeft:0, 
                            // borderStyle:index != 0 || index != criteria.subCriterias.length -1 ? "dotted": null,
                          }}>{sub.description}</td>
                          <td style={{ width: 0, borderRight: "1.7px solid #F8FDFF", borderLeft: "1.7px solid #F8FDFF" }}></td>
                          <td style={{
                            // paddingLeft: 10, 
                            width: 1800,
                            fontWeight: "bold",
                            color: "#385623",
                            fontSize: 15, 
                            border:"3px solid #385623",
                            borderTop:index != 0 || index != criteria.subCriterias.length -1 ? "1px solid #385623": null,
                            borderBottom:index != 0 || index != criteria.subCriterias.length -1 ? "1px solid #385623": null,
                            borderRight:"0",
                            overflow:"hidden",
                            whiteSpace:"nowrap",
                          }}>{sub.title}</td>
                          {resultColumns.map((result) => {
                            if (!subCriteriaResults[result.id + sub.id]) {
                              subCriteriaResults[result.id + sub.id] = {
                                isChecked: false,
                                subCriteriaId: sub.id,
                                resultId: result.id,
                              }
                            }
                            return (
                              <>
                                <td key={`${result.id}-${sub.id}`} style={{
                                  textAlign: "center",
                                  border:"1px solid #385623"
                                }}>
                                  <Checkbox
                                    style={{
                                      width: 20,
                                      height: 20,
                                      color: "#385623",
                                      // backgroundColor:"red"

                                    }}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                    type="checkbox"
                                    checked={
                                      subCriteriaResults[result.id + sub.id]
                                        ?.isChecked || false
                                    }
                                    onChange={(event) => {
                                      setIsDataChange(true)
                                      const isChecked = event.target.checked

                                      const tmp =
                                        subCriteriaResults[result.id + sub.id]
                                      tmp.isChecked = isChecked
                                      const subRes = {
                                        ...subCriteriaResults,
                                        [result.id + sub.id]: tmp,
                                      }
                                      setSubcriteriaResults(subRes)
                                      const re = calculateResult(
                                        criterias,
                                        result.id,
                                      )
                                      setFinalPercent({
                                        ...finalPercent,
                                        [result.id]: re,
                                      })
                                    }}
                                  />
                                </td>
                              </>
                            )
                          })}
                        </tr>
                      ))}
                    </>
                  ))}
                </tbody>
              </table>
              <div style={{ height: 30 }}></div>


            </div>
          </div>

        </div>
      </div>

      {/* <div
          style={{
            // border:"1px solid blue",
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            height: 76,
            backgroundColor: '#004A6C',
            justifyContent: 'space-between',
            paddingLeft: 50,
            paddingRight: 50,
            width: '80vw',
          }}
        >
          <div
            style={{
              color: 'white',
              fontSize: 20,
              // fontWeight:"bold"
            }}
          >
            Table has been updated, would you like to save changes?{' '}
          </div>
          <Button
            variant="contained"
            // startIcon={<PostAddRoundedIcon />}
            onClick={handleSaveDataChange}
            style={{
              padding: 15,
              border: '1px solid white',
              backgroundColor: 'white',
              borderRadius: 100,
              fontWeight: 'bold',
              // margin: 10
              color: 'black',
            }}
          >
            Save All Changes
          </Button>
        </div> */}

      {/* DIALOG 1 */}
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose(event, reason);
          }
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableBackdropClick


      >
        <DialogTitle id="alert-dialog-title">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div style={{ paddingTop: 4 }}><CircularProgress style={{ color: "#0f3c50" }} /></div>
            {/* <div style={{paddingLeft: 7, fontSize: 15}}>{"Save changes, loading..."}</div> */}
          </div>
        </DialogTitle>
      </Dialog>

      {/* DIALOG 2  */}
      <Dialog
        open={openWarnDialog}
        onClose={handleCloseWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to quit?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There are unsaved changes in the table, would you like to save changes?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 20
          }}
        >
          <div>
            <Button onClick={handleCloseWarning} style={{ textTransform: 'none', paddingLeft: 20 }}>Close this</Button>
            <Button onClick={forceExitWithoutSave} style={{ textTransform: 'none', paddingLeft: 30, color: "red", fontWeight: "bold" }}>Quit, without save</Button>
          </div>
          <Button variant="contained" onClick={handleSaveDataChangeWhenTryToQuit} autoFocus style={{ textTransform: 'none', fontWeight: "bold", backgroundColor: "#0f3c50", marginRight: 10 }}>
            Save changes and exit
          </Button>

        </DialogActions>
      </Dialog>


      {/* Dialog 3, warning if user want to delete evaluation column, but not save all changes yet  */}
      <Dialog
        open={openDeleteWarning}
        onClose={handleCloseDeleteWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Delete warning!"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            There are unsaved changes data in the table, you need to save change first before proceed to delete action?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            justifyContent: "space-between",
            paddingBottom: 20
          }}
        >
          <Button onClick={handleCloseDeleteWarning} style={{ textTransform: 'none', paddingLeft: 20 }}>Cancel</Button>
          <Button variant="contained" onClick={handleSaveDataChangeWhenTryToQuit} autoFocus style={{ textTransform: 'none', fontWeight: "bold", backgroundColor: "#0f3c50", marginRight: 10 }}>
            Confirm & Save all changes
          </Button>

        </DialogActions>
      </Dialog>
    </div>
  )
}

export default BarometerEvaluation
