import React, { useState, useEffect } from 'react';
import HeaderBar from '../../../Components/HeaderBar';
import { useNavigate, useLocation } from "react-router-dom";
import { 
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent,
    DialogTitle, 
    TextField, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow, 
    Paper, 
    LinearProgress,  
    IconButton
} from '@mui/material';
import './style.module.css'

import axios from 'axios';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import url from "../../../Constants/URL.json"
import DomainAddRoundedIcon from '@mui/icons-material/DomainAddRounded';

const BarometerClientList = () => {
    const navigate = useNavigate();
    const BASE_API_URL = url.BASE_API_URL
    const token = localStorage.getItem("token");
    const headers = {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }

    
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [selectedRow, setSelectedRow] = useState(null);
    const [loading, setLoading] = useState(false); // initialize loading state to true

    useEffect(() => {
        const token = localStorage.getItem('token');
        const config = {
          headers: { Authorization: `Bearer ${token}` }
        };
        axios.get(`${BASE_API_URL}/barometers/company`, config)
          .then(response => {
            setData(response.data);
            setLoading(false);
          })
          .catch(error => {
            console.log(error);
          });
    }, [loading]);
    
    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleCompanyNameChange = (event) => {
        setCompanyName(event.target.value);
    };

    const handleCreateClient = () => {
        setCompanyName('');
        setOpen(false);
        createNewClient();
        setLoading(!loading)
    };

    const createNewClient = () => {
        const data = {
            companyName: companyName,
        };

        axios.post(`${BASE_API_URL}/barometers/company`, data, headers)
            .then(response => {
                console.log("- After create company response: ", response.data.id);
                const companyId = response.data.id;
                // createNewResult(companyId)
                createCriteriaAndSubCriteria(companyId)
            })
            .then(() => {
                handleClose();
            })
            .catch(error => {
                console.log(error);
            });
    }




    const createCriteriaAndSubCriteria = (companyId) => {
        const data = [
          {
            title: 'I. Business value of digital services delivered',
            weight: 1,
            num: 1,
            companyId: companyId,
            subCriterias: [
              {
                title: '1. A business value is assigned at the feature level',
                percentageValue: 34,
                num: 1,
                description: 'If no 0%, if yes 34%',
              },
              {
                title:
                  '2. A business value is assigned at the user story level',
                percentageValue: 33,
                num: 2,
                description: 'If yes, 33%',
              },
              {
                title: '3. An user value is assigned at the feature level',
                num: 3,
                percentageValue: 33,
                description: 'If yes, 33%',
              },
            ],
          },
          {
            title: 'II. Respect of the deadlines',
            weight: 1,
            num: 2,
            companyId: companyId,
            subCriterias: [
              {
                title:
                  '1. Respect of the initial deadlines (in production for the customer)',
                percentageValue: 100,
                num: 1,
                description: '100%',
              },
              {
                title:
                  '2. Minor delays but anticipated and without business or budget impacts but anticipated and without business impact or budget',
                percentageValue: 80,
                num: 2,

                description: '80%',
              },
              {
                title:
                  '3. Final delivery modified on several occasions requiring adaptations of the perimeter',
                percentageValue: 50,
                num: 3,

                description: '50%',
              },
              {
                title: '4. Delays that are not anticipated and/or controlled',
                num: 4,

                percentageValue: 20,
                description: '20%',
              },
              {
                title: '5. No visibility on the final delivery',
                num: 5,

                percentageValue: 0,
                description: '0%',
              },
            ],
          },
          {
            title: 'III. Respect of the budget',
            weight: 1,
            companyId: companyId,
            num: 3,

            subCriterias: [
              {
                title: '1. Respect of the initial budget',
                percentageValue: 100,
                num: 1,

                description: '100%',
              },
              {
                title: '2. Overrun of 20% or less of the initial budget',
                percentageValue: 70,
                description: '70%',
                num: 2,
              },
              {
                title: '3. Overrun between 20% and 50% of the initial budget',
                percentageValue: 50,
                num: 3,

                description: '50%',
              },
              {
                title: '4. Overrun of 50% or more of the initial budget',
                num: 4,

                percentageValue: 0,
                description: '0%',
              },
            ],
          },
          {
            title: 'IV. Release plan formalized and stable',
            weight: 1,
            num: 4,

            companyId: companyId,
            subCriterias: [
              {
                title:
                  '1. Release plan formalized during the scoping phase and stable throughout the iteration',
                percentageValue: 100,
                description: '100%',
                num: 1,
              },
              {
                title:
                  '2. Release plan formalized in the scoping phase which slightly changed during the delivery phase but respected',
                percentageValue: 80,
                description: '80%',
                num: 2,
              },
              {
                title:
                  '3. Release plan formalized late and/or inaccurate and not well respected',
                percentageValue: 40,
                description: '40%',
                num: 3,
              },
              {
                title: '4. No release plan',
                percentageValue: 0,
                description: '0%',
                num: 4,
              },
            ],
          },
          {
            title: 'V. Release plan perspective (goal : 2 iterations)',
            weight: 1,
            num: 5,

            companyId: companyId,
            subCriterias: [
              {
                title:
                  '1. Release plan formalized during the scoping phase for the next 2 iterations',
                percentageValue: 100,
                description: '100%',
                num: 1,
              },
              {
                title:
                  '2. No release plan formalized during the scoping phase for the next 2 iterations',
                percentageValue: 0,
                description: '0%',
                num: 2,
              },
            ],
          },
          {
            title: 'VI. Precise Business case',
            num: 6,
            weight: 1,
            companyId: companyId,
            subCriterias: [
              {
                title: '1. Business case formalized and precise',
                percentageValue: 100,
                num: 1,

                description: '100%',
              },
              {
                title: '2. Business case formalized but inaccurate',
                percentageValue: 50,
                num: 2,

                description: '50%',
              },
              {
                title: '3. No Business case',
                percentageValue: 0,
                num: 3,

                description: '0%',
              },
            ],
          },
          {
            title: 'VII. Respect of the Business case',
            weight: 1,
            num: 7,

            companyId: companyId,
            subCriterias: [
              {
                title:
                  '1. Respect of the business case during the deployment (From year 1)',
                percentageValue: 100,
                description: '100%',
                num: 1,
              },
              {
                title:
                  '2. Respect of the business case during the deployment (from year 1) at +/- 20%',
                percentageValue: 50,
                description: '50%',
                num: 2,
              },
              {
                title:
                  '3. Business case not respected by 20% or more (less revenue and/or more expenses)',
                percentageValue: 0,
                description: '0%',
                num: 3,
              },
              {
                title:
                  '4. Revenues greater than 20% of business case and equal or less expenses than expected',
                percentageValue: 130,
                description: '130%',
                num: 4,
              },
            ],
          },
        ]


        axios.post(`${BASE_API_URL}/barometers/criteria/bulk`, data, headers)
            .then(response => {
                console.log("- Create bulk Criteria: ", response);
            })
            .catch(error => {
                console.log(error);
                }
            );
    }

    const deleteClient = (companyId) => {
        const token = localStorage.getItem("token");
        // Set the endpoint URL and company ID
        const endpoint = `${BASE_API_URL}/barometers/company/${companyId}`;

        // Set the headers including the authorization token
        const headers = {
        Authorization: `Bearer ${token}`,
        };
        
        // Send the DELETE request with axios
        axios.delete(endpoint, { headers })
            .then((response) => {
            console.log(response);
            setLoading(!loading)
        })
        .catch((error) => {
            console.error(error);
        });
    }

    return(
        <div>
            <HeaderBar title="Digital Factory Barometer"/>
            <div
                style={{ 
                    height: 'calc(85vh)', 
                    overflowY: 'scroll', 
                    padding: '20px', 
                    width: "82vw"
                  }}
            >
                <div
                    style={{
                        display:"flex", 
                        flexDirection:"row",
                        alignItems:"center", 
                        justifyContent:"space-between", 
                        marginBottom: 20, 
                    }}
                >
                    <div>Client List</div>
                    <div>
                        <Button 
                            variant="contained" 
                            style={{
                                backgroundColor:"#0f3c50",
                                textTransform: 'none', 
                                height: 60, 
                                borderRadius: 100, 
                                width: 180
                            }}
                            startIcon={<DomainAddRoundedIcon style={{fontSize: 25}}/>}
                            onClick={handleOpen}
                        >
                            Add new client
                        </Button>
                    </div>
                </div>
                <div>
                    <div>
                        {
                            loading ? <LinearProgress style={{backgroundColor:"green"}}/> :
                            <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }}  aria-label="company table">
                                <TableHead>
                                <TableRow style={{
                                  backgroundColor:"#0f3c50"
                                }}>
                                    <TableCell style={{border:"1.7px solid #0f3c50", fontWeight:"bold", backgroundColor:"#0f3c50", color:"white"}}>No</TableCell>
                                    <TableCell style={{border:"1.7px solid #0f3c50", fontWeight:"bold", backgroundColor:"#0f3c50", color:"white"}}>Company Name</TableCell>
                                    <TableCell style={{border:"1.7px solid #0f3c50", fontWeight:"bold", backgroundColor:"#0f3c50", color:"white"}}>Date Created</TableCell>
                                    <TableCell style={{border:"1.7px solid #0f3c50", fontWeight:"bold", backgroundColor:"#0f3c50", color:"white"}}>Action</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {data.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((row, index) => (
                                          <TableRow
                                              key={row.id}
                                              hover
                                              onClick={() => {
                                                  console.log("fire: ", row.id)
                                                  setSelectedRow(row)
                                                  navigate('/barometer-evaluation', { state: row })
                                              }}
                                              sx={{ cursor: 'pointer' }}
                                              selected={selectedRow && selectedRow.id === row.id}
                                          >
                                              <TableCell style={{border:"1.7px solid #F8FDFF"}} component="th" scope="row">{index + 1}</TableCell>
                                              <TableCell style={{border:"1.7px solid #F8FDFF"}}><div style={{
                                                border:row.companyName === "testing" ? "1px solid red" : "", width:row.companyName === "testing" ?"fit-content":"", borderRadius:row.companyName === "testing" ?100:0, padding:row.companyName === "testing" ? "2px 8px":"", backgroundColor:row.companyName === "testing" ? "red":"", color:row.companyName === "testing" ? "white":""
                                              }}>{row.companyName}</div></TableCell>
                                              <TableCell style={{border:"1.7px solid #F8FDFF"}}>
                                                {new Date(row.createdAt).toLocaleDateString('en-US', { month: 'short' })}/
                                                {new Date(row.createdAt).getDate()}/
                                                {new Date(row.createdAt).getFullYear()}
                                              </TableCell>
                                              <TableCell style={{
                                                  overflow:"hidden",
                                                  border:"1.7px solid #F8FDFF"
                                              }}>
                                                  <IconButton
                                                      onClick={(event) => {
                                                          event.stopPropagation();
                                                          deleteClient(row.id)
                                                      }}
                                                  >
                                                      <DeleteRoundedIcon style={{color:"#0f3c50"}} />
                                                  </IconButton>
                                              </TableCell>
                                          </TableRow>
                                      ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        }
                    </div>
                </div>
            </div>

            {/* Dialog */}
            <Dialog open={open} onClose={handleClose} fullWidth>
                <DialogTitle>Add New Client</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="Enter Company Name"
                    fullWidth
                    value={companyName}
                    onChange={handleCompanyNameChange}
                />
                </DialogContent>
                <DialogActions>
                {/* <Button style={{textTransform: 'none', color:"#0f3c50"}}  onClick={handleClose}>Cancel</Button> */}
                <Button 
                    style={{
                        textTransform: 'none', 
                        backgroundColor:"#0f3c50", 
                        color:"white", 
                        width: 150
                    }} 
                    onClick={handleCreateClient} 
                    disabled={!companyName}
                >
                    Confirm
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BarometerClientList;