import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Home, Login } from "../Pages";
import Cookie from "universal-cookie";
import Layout from "./Layout";

const Navigations = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          <Route path={"/"} element={<Home />} />
          <Route path={"/login"} element={<Login />} />
          <Route path={"/clients"} element={<Home />} />
          <Route path={"/barometer-evaluation"} element={<Home />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Navigations;
