import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logo_mint from "../../Assets/Images/logo-mint.png";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Cookie from "universal-cookie";
import url from "../../Constants/URL.json";

const Login = () => {
  const navigate = useNavigate();
  const cookies = new Cookie();
  const BASE_API_URL = url.BASE_API_URL;
  const SUB_DOMAIN = url.SUB_DOMAIN;
  const INTRA_URL = url.INTRA_URL;

  const [email, setEmail] = useState(cookies.get("email"));
  const [password, setPassword] = useState(cookies.get("password"));
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleLogin = (token) => {
    localStorage.setItem("token", token);
    // Do something else after successful login, such as redirecting to another page
    navigate("/"); // Redirect to the home page after successful login
  };

  const handleSubmit = (event) => {
    // event.preventDefault();
    setLoading(true);
    setError("");

    axios
      .post(`${BASE_API_URL}/auth/login`, {
        email: email,
        password: password,
      })
      .then((response) => {
        const token = response.data.accessToken;
        handleLogin(token);
        console.log(response.data); // You can do something with the response data here
      })
      .catch((error) => {
        console.log(error); // You can handle errors here
        window.location.href = INTRA_URL
        // window.location.href = 'http://localhost:8081'
        // cookies.set('alert', "Maturity Barometer", { domain: 'localhost' })
        cookies.set('alert', "Maturity Barometer", { domain: SUB_DOMAIN })
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetCookie = () => {
    if (!cookies.get("isSignedOut")) {
      cookies.set("from", "MB", { domain: ".peppermint.company" });
    //   cookies.set("from", "MB", { domain: "localhost" }) //Dev
    }
    handleRedirect();
  };

  const handleRedirect = () => {
    // window.location.href = "http://localhost:8081" //Dev
    window.location.href = "https://intra.peppermint.company";
  };

  useEffect(() => {
    if (email && password) {
      handleSubmit()
    }
    else {
      handleSetCookie()
    }
  }, [email, password]);

  return (
    <Box>
      <Box
        sx={{
          zIndex: -1,
          position: "absolute",
          width: "100%",
          height: "100vh",
          background: "black",
          opacity: "0.5",
        }}
      />
      <Box
        sx={{
          zIndex: 2,
          position: "absolute",
          opacity: "1",
          width: "100%",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "transparent",
        }}
      >
        <CircularProgress />
      </Box>
    </Box>
    // <div
    //   style={{
    //     display: "flex",
    //     flex: 1,
    //     alignItems: "center",
    //     justifyContent: "center",
    //     alignContent: "center",
    //     width: "100vw",
    //     position: "static",
    //     border: "1px solid blue",
    //   }}
    // >
    //   <div>
    //     <div
    //       style={{
    //         display: "flex",
    //         alignItems: "center",
    //         justifyContent: "center",
    //         height: "100vh",
    //         flexDirection: "column",
    //       }}
    //     >
    //       <div>
    //         <img src={logo_mint} style={{ width: 130, marginTop: 20 }} />
    //       </div>
    //       <form onSubmit={handleSubmit}>
    //         <div style={{ textAlign: "center" }}>
    //           <div>
    //             <TextField
    //               id="email"
    //               label="Email*"
    //               variant="outlined"
    //               fullWidth={true}
    //               style={{
    //                 width: 350,
    //                 margin: 10,
    //               }}
    //               value={email}
    //               onChange={(event) => setEmail(event.target.value)}
    //             />
    //           </div>
    //           <div>
    //             <TextField
    //               id="password"
    //               label="Password*"
    //               type="password"
    //               variant="outlined"
    //               style={{
    //                 width: 350,
    //                 margin: 10,
    //               }}
    //               value={password}
    //               onChange={(event) => setPassword(event.target.value)}
    //             />
    //           </div>
    //           <div>
    //             {error && (
    //               <div
    //                 style={{
    //                   color: "red",
    //                 }}
    //               >
    //                 {error}
    //               </div>
    //             )}
    //             <div>
    //               <Button
    //                 type="submit"
    //                 variant="contained"
    //                 style={{
    //                   width: 350,
    //                   height: 50,
    //                   margin: 10,
    //                   backgroundColor: "#0f3c50",
    //                   textTransform: "none",
    //                   position: "relative",
    //                 }}
    //                 disabled={loading}
    //               >
    //                 {loading && (
    //                   <CircularProgress
    //                     size={24}
    //                     style={{
    //                       position: "absolute",
    //                       top: "50%",
    //                       left: "50%",
    //                       marginTop: -12,
    //                       marginLeft: -12,
    //                     }}
    //                   />
    //                 )}
    //                 Login
    //               </Button>
    //             </div>
    //           </div>
    //         </div>
    //       </form>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Login;
