import "./App.css";
import Navigations from "./Navigations";


function App() {
  return (
    <Navigations />
  );
}

export default App;