import React, {useState, useEffect} from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import style from "./style.module.css";
import Drawer from '../../Components/Drawer';
import BarometerClientList from '../Barometer/ClientList';
import BarometerEvaluation from '../Barometer/Evaluation2';

const Home = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [_childToParentMsg, _setChildToParentMsg] = useState("")

    useEffect(() => {
        _setChildToParentMsg("Maturity Barometer")
        // Check if token exists, e.g. by calling an API endpoint
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login");
        }
    }, [navigate]);


    return(
        <div>
            <div className={style.container}>
                {/* ===== REMARK: DRAWER SECTION ===== */}
                <Drawer  onChange={(value)=> _setChildToParentMsg(value)}/>

                {/* ===== REMARK: BODY CANVAS =====*/}
                <div className={style.bodyCanvasContainer}>
                    {(location.pathname == "/" || location.pathname == "/clients")&& <BarometerClientList />}
                    {(location.pathname == "/barometer-evaluation")&& <BarometerEvaluation />}
                </div>
            </div>
        </div>
    )
}

export default Home;