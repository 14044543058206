import { Outlet, redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";

export default function Layout() {
  const [hasToken, setHasToken] = useState(false);
  const cookies = new Cookies();
  const navigate = useNavigate();

  const handleSetCookie = () => {
    if (!cookies.get("isSignedOut")) {
      cookies.set("from", "MB", { domain: ".peppermint.company" });
      // cookies.set("from", "MB", { domain: "localhost" }) //Dev
    }
    handleRedirect();
  };

  const handleRedirect = () => {
    // window.location.href = "http://localhost:8081" //Dev
    window.location.href = "https://intra.peppermint.company";
  };

  useEffect(() => {
    if (!cookies.get("email")) {
      navigate("/login");
      handleSetCookie();
    }
  }, []);

  return <Outlet />;
}
