import React, { useEffect, useState } from 'react';
import styles from './style.module.css'
import { isDate } from 'moment/moment';
import axios from 'axios';
import { BorderBottom } from '@mui/icons-material';

const BarometerEvaluationTable = (props) => {
    console.log("props: ", props)
    let isChange = props.isDataChange;

    useEffect(() => {
        console.log("==================RERENDER========================")
    }, [isChange]);

    const levels = [
        { level: "Level 7", desc: "Digital factory delivering high business value digital products on time, on budget with a clear and shared roadmap for the next 2 iterations with predictable business cases", percent: 100},
        { level: "Level 6", desc: "Digital factory delivering high business value digital products on time and on budget with predictable business cases", percent: 90},
        { level: "Level 5", desc: "Digital factory delivering digital products but with occasional delays, but without impact on the business, with predictable business cases", percent: 80},
        { level: "Level 4", desc: "Digital factory delivering digital products but with minor budget and deadline overruns", percent: 60},
        { level: "Level 3", desc: "Digital factory delivering digital products but with significant budget and deadline overruns", percent: 40},
        { level: "Level 2", desc: "Digital factory delivering digital products that do not correspond to the needs with significant budget and deadline overruns", percent: 20},
        { level: "Level 1", desc: "Digital Factory with little efficiency, giving a costly and unsatisfactory delivery", percent: 10}
    ];

    // console.log("=================: ", props?.dataToTable[0]?.percentageResult == levels.percent)

    // console.log("props.dataToTable: ", props.dataToTable)

    return(
        <div>
            <div>
                {/* <div style={{marginLeft: "73%"}}>score</div> */}
                <div
                    style={{
                        display:"flex",
                        flexDirection:"row", 
                        // border:"1px solid blue",
                        // borderRadius: 10, 
                        overflow:"hidden", 
                        // border:"3px solid black",
                        backgroundColor:"white",
                        width:"fit-content",
                        // padding: 8, 
                    }}
                >
                    <div style={{
                        display:"flex",
                        // border:"3px solid black",
                        // borderRight:"2px solid black",
                        alignItems:"center",
                        width: 220,
                        // borderRadius: 10, 
                        // overflow:"hidden", 
                        // backgroundColor:"#EBEBEB", 
                        // padding: 10, 
                        fontWeight:"bold",
                        textAlign:"center",
                        fontSize: "1rem"
                    }}></div>
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div
                                    style={{
                                        display: "flex", flexDirection: "row",
                                        // borderTop: index==0?"2px solid black": null,
                                        // borderRight: "2px solid black",
                                        // borderBottom: index==levels.length-1?"2px solid black": null,
                                    }}
                                >
                                    <div style={{ width: "4vw" }}>
                                        
                                    </div>
                                    <div style={{ width: "45vw" }}>
                                       
                                    </div>
                                    <div style={{ width: "4vw",textAlign:"center" }}>
                                    Score
                                    </div>
                                </div>
                                <div style={{ 
                                        width: "20vw",
                                        // height: 50, 
                                        overflow:"hidden", 
                                        display:"flex", 
                                        flexDirection:"row", 
                                        fontSize: 12, 
                                        alignItems:"center"
                                    }}>
                                
                                </div>
                            </div>
                       
                    </div>
                </div>
                <div
                    style={{
                        display:"flex",
                        flexDirection:"row", 
                        // border:"1px solid blue",
                        // borderRadius: 10, 
                        overflow:"hidden", 
                        // border:"3px solid black",
                        backgroundColor:"white",
                        width:"fit-content",
                        // padding: 8, 
                    }}
                >
                    <div style={{
                        display:"flex",
                        border:"3px solid black",
                        borderRight:"2px solid black",
                        alignItems:"center",
                        width: 220,
                        // borderRadius: 10, 
                        // overflow:"hidden", 
                        // backgroundColor:"#EBEBEB", 
                        // padding: 10, 
                        fontWeight:"bold",
                        textAlign:"center",
                        fontSize: "1rem"
                    }}>Digital Delivery maturity barometer</div>
                    <div
                        style={{
                            display:"flex",
                            flexDirection:"column"
                        }}
                    >
                        {levels.map((level, index) => (
                            <div style={{ display: "flex", flexDirection: "row" }}>
                                <div
                                    style={{
                                        display: "flex", flexDirection: "row",
                                        borderTop: index==0?"2px solid black": null,
                                        borderRight: "2px solid black",
                                        borderBottom: index==levels.length-1?"2px solid black": null,
                                    }}
                                >
                                    <div style={{ width: "4vw" }}>
                                        <div className={`${styles.level} ${styles[`bg${7 - index}`]}`}>{level.level}</div>
                                    </div>
                                    <div style={{ width: "45vw" }}>
                                        <div className={`${styles.desc} ${styles[`bg${7 - index}`]}`}>{level.desc}</div>
                                    </div>
                                    <div style={{ width: "4vw" }}>
                                        <div className={`${styles.level} ${styles[`bg${7 - index}`]}`}>{"< " + level.percent + " %"}</div>
                                    </div>
                                </div>
                                <div style={{ 
                                        width: "20vw",
                                        height: 50, 
                                        overflow:"hidden", 
                                        display:"flex", 
                                        flexDirection:"row", 
                                        fontSize: 12, 
                                        alignItems:"center"
                                    }}>
                                {
                                    props?.dataToTable.map((item, index) => {
                                        const dateObj = new Date(item.date);
                                        
                                        const formattedDate = `${dateObj.getDate()}/${(dateObj.getMonth() + 1).toString().padStart(2, '0')}`;
                                        if (item.percentageResult >= 0 && item.percentageResult <= 9 && level.level === "Level 1") {
                                          return <div  className={`${styles.result} ${styles.resultColor1}`}>{props.companyName} on {formattedDate}</div>;
                                        } else if (item.percentageResult >= 10 && item.percentageResult <= 19 && level.level === "Level 2") {
                                          return <div  className={`${styles.result} ${styles.resultColor2}`}>{props.companyName} on {formattedDate}</div>;
                                        } else if (item.percentageResult >= 20 && item.percentageResult <= 39 && level.level === "Level 3") {
                                          return <div  className={`${styles.result} ${styles.resultColor3}`}>{props.companyName} on {formattedDate}</div>;
                                        } else if (item.percentageResult >= 40 && item.percentageResult <= 59 && level.level === "Level 4") {
                                          return <div  className={`${styles.result} ${styles.resultColor4}`}>{props.companyName} on {formattedDate}</div>;
                                        } else if (item.percentageResult >= 60 && item.percentageResult <= 79 && level.level === "Level 5") {
                                          return <div  className={`${styles.result} ${styles.resultColor5}`}>{props.companyName} on {formattedDate}</div>;
                                        } else if (item.percentageResult >= 80 && item.percentageResult <= 89 && level.level === "Level 6") {
                                          return <div  className={`${styles.result} ${styles.resultColor6}`}>{props.companyName} on {formattedDate}</div>;
                                        } else if (item.percentageResult >= 90 && level.level === "Level 7") {
                                          return <div  className={`${styles.result} ${styles.resultColor7}`}>{props.companyName} on {formattedDate}</div>;
                                        } else {
                                          return null;
                                        }
                                      })
                                    }
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BarometerEvaluationTable;